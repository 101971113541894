.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  background:#E4E0EE;
}

.title {
  font-size: 7vmin !important;
}

.homeTablePaper {
  width: 80vmin !important;
}

.homeTableHead {
  font-size: 4.8vmin !important;
  font-weight: bold !important;
}

.MuiTextField-root {
  max-width: 14vmin;
  background-color: white;
}

.distText {
  max-width: 22vmin !important;
}

.MuiButton-root {
  font-size: 3.5vmin !important;
}

.nutcLogo {
  width: 60vmin;
  height: 23.34vmin;
}

/*had to do this due to weird rendering on iOS */
#literally-blank {
  user-select: none;
}


@media screen and (min-width: 768px){
  .title {
    font-size: 36px !important;
  }
  .homeTablePaper {
    width: 530px !important;
  }
  .homeTableHead {
    font-size: 20px !important;
  }
  .MuiTextField-root {
    max-width: 100px;
  }
  .MuiButton-root {
    font-size: 13px !important;
  }
  .nutcLogo {
    width: 300px;
    height: 116.71px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
